import { useMutation, useQuery } from "urql";

const DOCUMENTS = `
query GET_PAGINATED_DOCUMENTS($input: PaginatedInput!) {
    paginatedDocumentsByCompany(input: $input) {
      totalDocs
      docs {
        id
        currencyType
        companyId
        name
        description
        amount
        documentDate
        isHidden
        isManual
        documentType
        macroCategory
        category
        eerrArea
        cashflowArea {
          id
          name
        }
        company {
          id
          name
        }
        meta {
          ... on TaxDocumentMeta {
            documentId
            documentCode
            originalAmount
            netAmount
            taxAmount
            state
            otherTaxes {
                name
                value
                taxRate
                code
              }
            issuedAt
            dueDate
            isPaid
            isReceived
            paymentDate
            issuerId
            issuerName
            receiverId
            receiverName
            taxRate
            remaining
            downloadURL
            description {
              item
              name
              description
              quantity
              unit
              unit_price
              total_price
              discount
              discount_percentage
            }
            summary
            isValid
            inform {
             amountInform
             isInform
             remaining
             informDate
            }
            matchs {
              inMatch {
                id
                amount
                selected
                userRelated {
                  userId
                  firstName
                  lastName
                  date
                }
                accountPlan {
                  level1 {
                    value
                    label
                  }
                  level2 {
                    value
                    label
                  }
                  level3 {
                    value
                    label
                  }
                }
              }
              outMatch {
                id
                amount
                selected
                userRelated {
                  userId
                  firstName
                  lastName
                  date
                }
                accountPlan {
                  level1 {
                    value
                    label
                  }
                  level2 {
                    value
                    label
                  }
                  level3 {
                    value
                    label
                  }
                }
              }
            }
            innerComments {
              transactionId
              user {
                id
                name
                email
              }
              date
              comment
            }
          }
        }
        meta {
        ... on ObligationMeta {
          family
          familyLabel
          type
          typeLabel
          description
          detail {
            description 
            item
            quantity
            unit_price
            total_price
          }
          date
          number
          documentId
          amount
          rate
          referenceNumber
          currency
          isPaid
          remaining
          originalAmount
          documentDate
          taxRate
          issuerId
          issuerName
          receiverId
          receiverName
          isReceived
          matchs {
            inMatch {
              id
              amount
              selected
              userRelated {
                userId
                firstName
                lastName
                date
              }
              accountPlan {
                level1 {
                  value
                  label
                }
                level2 {
                  value
                  label
                }
                level3 {
                  value
                  label
                }
              }
            }
            outMatch {
              id
              amount
              selected
              userRelated {
                userId
                firstName
                lastName
                date
              }
              accountPlan {
                level1 {
                  value
                  label
                }
                level2 {
                  value
                  label
                }
                level3 {
                  value
                  label
                }
              }
            }
          }
        }
      }
      }
    }
  } 
`;

export const GetDocuments = ({
  companyId,
  pagination,
  filter,
  search,
  detalle,
  rut,
  newFilter,
  documentTypes,
  isMatch,
}) => {
  const [result, reexecuteQuery] = useQuery({
    query: DOCUMENTS,
    variables: {
      input: {
        companyId,
        pagination,
        filter,
        search,
        newFilter,
        documentTypes,
        isMatch,
      },
    },
  });
  const { data, fetching, error } = result;
  return { data, fetching, error, reexecuteQuery };
};

export const SETTLE_EXCEL = `
  mutation SETTLE_EXCEL(
    $data: typeMasive!
  ) {
    settleExcel(
      data: $data
    )
  }
`;

export const SettleExcel = () => {
  const [result, reexecuteMutation] = useMutation(SETTLE_EXCEL);
  const { data, fetching, error } = result;
  return { data, fetching, error, reexecuteMutation };
};

const DOWNLOAD_DOCUMENTS = ` mutation DOWNLOAD_DOCUMENTS($input: FilteredDocumentInput!) {
    downloadDocuments(input: $input)
}`;

export const DownloadDocuments = () => {
  const [result, executeMutation] = useMutation(DOWNLOAD_DOCUMENTS);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const MATCH_DTES = `mutation MATCH_DTES($match: MatchInput!) {
  matchTransactionDtes(match: $match) {
    id
    amount
  }
}`;

export const MatchDtes = () => {
  const [result, matchDtes] = useMutation(MATCH_DTES);
  const { data, fetching, error } = result;
  return { data, fetching, error, matchDtes };
};

const CLASSIFY_DTES = `mutation CLASSIFY_DTES($documents:[String!]!, $fieldKey: String!) {
  setCategory(documents:$documents, fieldKey: $fieldKey)
}`;

export const ClassifyDtes = () => {
  const [result, classifyDtes] = useMutation(CLASSIFY_DTES);
  const { data, fetching, error } = result;
  return { data, fetching, error, classifyDtes };
};

const SETTLE_DOCUMENT_AND_POINT = `mutation SETTLE_DOCUMENT_AND_POINT(
  $id: String!,
  $originalAmount: Float!,
  $remaining: Float!,
  $isPaid: Boolean!,
  ) {
  settleBothDocumentAndPoint(
    id: $id,
    originalAmount: $originalAmount,
    remaining: $remaining,
    isPaid: $isPaid,
  ) {
    id
    amount
  }
}`;

export const SettleBothDocumentAndPoint = () => {
  const [result, executeMutation] = useMutation(SETTLE_DOCUMENT_AND_POINT);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const MATCH_DOCUMENT_WITH_COUNTER_PART = `mutation MATCH_DOCUMENT_WITH_COUNTER_PART($match: MatchInput!) {
  matchDocumentWithCounterPart(match: $match) {
      id
      companyId
      amount
      meta {
        ... on TaxDocumentMeta {
          documentId
          documentCode
          originalAmount
          netAmount
          taxAmount
          issuedAt
          dueDate
          downloadURL
          isPaid
          isReceived
          paymentDate
          issuerId
          issuerName
          receiverId
          receiverName
          taxRate
          remaining
          summary
          matchs {
            inMatch {
              id
              amount
              selected
            }
            outMatch {
              id
              amount
              selected
            }
          }
        }
      }
      meta {
      ... on ObligationMeta {
        family
        familyLabel
        type
        typeLabel
        description
        date
        number
        amount
        currency
        isPaid
        remaining
        originalAmount
        taxRate
        issuerId
        issuerName
        receiverId
        receiverName
        isReceived
        matchs {
          inMatch {
            id
            amount
            selected
          }
          outMatch {
            id
            amount
            selected
          }
        }
      }
    }
  }
}`;

export const MatchDocumentWithCounterPart = () => {
  const [result, executeMutation] = useMutation(
    MATCH_DOCUMENT_WITH_COUNTER_PART
  );
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const DELETE_MATCH_BETWEEN_DOCUMENT_WITH_COUNTER_PART = `mutation DELETE_MATCH_BETWEEN_DOCUMENT_WITH_COUNTER_PART(
  $transactionId: String!,
  $documentId: String!,
  ) {
  deleteMatchBetweenDocumentAndDocument(transactionId: $transactionId, documentId: $documentId) {
      id
      companyId
      amount
      meta {
        ... on TaxDocumentMeta {
          documentId
          documentCode
          originalAmount
          netAmount
          taxAmount
          issuedAt
          downloadURL
          dueDate
          isPaid
          isReceived
          paymentDate
          issuerId
          issuerName
          receiverId
          receiverName
          taxRate
          remaining
          summary
          matchs {
            inMatch {
              id
              amount
              selected
            }
            outMatch {
              id
              amount
              selected
            }
          }
        }
      }
      meta {
      ... on ObligationMeta {
        family
        familyLabel
        type
        typeLabel
        description
        date
        number
        amount
        currency
        isPaid
        remaining
        originalAmount
        taxRate
        issuerId
        issuerName
        receiverId
        receiverName
        isReceived
        matchs {
          inMatch {
            id
            amount
            selected
          }
          outMatch {
            id
            amount
            selected
          }
        }
      }
    }
  }
}`;

export const DeleteMatchBetweenDocuments = () => {
  const [result, executeMutation] = useMutation(
    DELETE_MATCH_BETWEEN_DOCUMENT_WITH_COUNTER_PART
  );
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const BOOK = `mutation GET_BOOK(
  $companyId: String!,
  $isReceived: Boolean!,
  $isBoleta: Boolean!,
  $period: String!,
  $documentType: String!
  ) {
    getRCVBook(
      companyId: $companyId,
      isReceived: $isReceived
      isBoleta: $isBoleta,
      period: $period,
      documentType: $documentType
      ) {
      data {
        _id
        documentDate
        amount
        documentCode
        documentId
        obligationId
        obligationType
        documentType
        summary
        receiverId
        receiverName
        issuerId
        issuerName
        taxableAmount
        nonHoldableTaxes
        nonRecoverableTaxes
        lateTaxes
        nonTaxableAmount
        taxAmount
        isReceived
      }
      resume {
        _id
        count
        summary
        netAmount
        taxAmount
        lateTaxes
        nonTaxableAmount
        taxableAmount
        amount
      }
    }
}`;

export const GetBookByPeriod = () => {
  const [result, executeMutation] = useMutation(BOOK);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const DOWNLOAD_BOOK = `mutation DOWNLOAD_BOOK(
  $companyId: String!,
  $type: String!,
  $period: String!,
  $ids: [String!]!,
  ) {
    downloadRCVBook(
      companyId: $companyId,
      type: $type
      period: $period,
      ids: $ids,
    )
}`;

export const DownloadBook = () => {
  const [result, executeMutation] = useMutation(DOWNLOAD_BOOK);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const COUNT_DOCS_RCV = `mutation RCV_COUNT_DOCS(
  $companyId: String!,
  $type: String!,
  $periods: [String!],
  $dateStart: String,
  $dateEnd: String
) {
  countDocumentsRCVBook(
    companyId: $companyId,
    type: $type,
    periods: $periods,
    dateStart: $dateStart,
    dateEnd: $dateEnd
  )
}`;

export const CountDocumentsRCV = () => {
  const [result, executeMutation] = useMutation(COUNT_DOCS_RCV);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const GET_DOCS_RCV = `mutation GET_DOCS_RCV(
  $companyId: String!,
  $type: String!,
  $periods: [String!],
  $dateStart: String,
  $dateEnd: String
){
  getDocsRCVBook(
    companyId: $companyId,
    type: $type,
    periods: $periods,
    dateStart: $dateStart,
    dateEnd: $dateEnd
  ) {
    data {
      _id
      documentDate
      amount
      documentCode
      documentId
      obligationId
      obligationType
      documentType
      summary
      receiverId
      receiverName
      issuerId
      issuerName
      taxableAmount
      nonHoldableTaxes
      nonRecoverableTaxes
      lateTaxes
      nonTaxableAmount
      taxAmount
      isReceived
      isValid
    }
    resume {
      _id
      count
      summary
      netAmount
      taxAmount
      grossAmount
      lateTaxes
      nonTaxableAmount
      taxableAmount
      amount
    }
  }
}`;

export const GetDocumentsRCV = () => {
  const [result, executeMutation] = useMutation(GET_DOCS_RCV);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};


const GET_DOCS_RCV_ID = `mutation GET_DOCS_RCV_ID(
  $selectedDocs: [String!],
){
  getDocsRCVBookById(
    ids: $selectedDocs,
  ){
    data {
      _id
      documentDate
      amount
      documentCode
      documentId
      obligationId
      obligationType
      documentType
      summary
      receiverId
      receiverName
      issuerId
      issuerName
      taxableAmount
      nonHoldableTaxes
      nonRecoverableTaxes
      lateTaxes
      nonTaxableAmount
      taxAmount
      isReceived
      isValid

    }
    resume {
      _id
      count
      summary
      netAmount
      taxAmount
      grossAmount
      lateTaxes
      nonTaxableAmount
      taxableAmount
      amount
    }
  }
}`;


export const GetDocumentsRCVByID = () => {
  const [result, executeMutation] = useMutation(GET_DOCS_RCV_ID);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const DOWNLOAD_MULTI_PAYOFF = `mutation DOWNLOAD_RCV_MULTI_PAYOFF(
  $companyId: String!,
  $type: String!,
  $periods: [String!],
  $dateStart: String,
  $dateEnd: String
){
  downloadMultiPayoff(
    companyId: $companyId,
    type: $type,
    periods: $periods,
    dateStart: $dateStart,
    dateEnd: $dateEnd
  )
}`;

export const DownloadMultiPayoff = () => {
  const [result, executeMutation] = useMutation(DOWNLOAD_MULTI_PAYOFF);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const DOWNLOAD_MULTI_PAYOFF_BY_ID = `mutation DOWNLOAD_RCV_MULTI_PAYOFF_BY_ID(
  $type : String!,
  $selectedDocs: [String!],
){
  downloadMultiPayoffById(
    type: $type,
    ids: $selectedDocs,
  )
}`;

export const DownloadMultiPayoffById = () => {
  const [result, executeMutation] = useMutation(DOWNLOAD_MULTI_PAYOFF_BY_ID);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const RCV_PERIODS = `mutation RCV_PERIODS(
  $companyId: String!,
  $type: String!,
  ) {
    getPeriodsAvailable(
      companyId: $companyId,
      type: $type
    )
}`;

export const RCVPeriods = () => {
  const [result, executeMutation] = useMutation(RCV_PERIODS);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const FIND_AND_SETTLE = `mutation FIND_AND_SETTLE(
  $input: FilteredDocumentInput!,
  ) {
    FindAndSettleDocumentMassive(
      input: $input,
    )
}`;

export const FindAndSettleDocuments = () => {
  const [result, executeMutation] = useMutation(FIND_AND_SETTLE);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const FIND_AND_SETTLE_BY_ID = `mutation FIND_AND_SETTLE_BY_ID(
  $ids: [String!]!,
  ) {
    FindAndSettleDocumentById(
      ids: $ids,
    )
}`;

export const FindAndSettleDocumentsById = () => {
  const [result, executeMutation] = useMutation(FIND_AND_SETTLE_BY_ID);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const FIND_AND_PARTIAL_SETTLE_BY_ID = `mutation FIND_AND_PARTIAL_SETTLE_BY_ID(
  $ids: [PartialSettle!]!,
  ) {
    FindAndSettlePartialsDocumentById(
      ids: $ids,
    )
}`;

export const FindAndPartialSettleDocumentsById = () => {
  const [result, executeMutation] = useMutation(FIND_AND_PARTIAL_SETTLE_BY_ID);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const FIND_DOCUMENT_BY_ID = `query FIND_DOCUMENT_BY_ID($ids: [String!]!) {
  findDocumentsById(ids: $ids) {
      id
      currencyType
      companyId
      name
      description
      amount
      documentDate
      isHidden
      isManual
      documentType
      fieldKey
      originalDueDate
      dueDate
      meta {
        ... on TaxDocumentMeta {
          documentId
          documentCode
          originalAmount
          netAmount
          downloadURL
          taxAmount
          issuedAt
          dueDate
          isPaid
          isReceived
          paymentDate
          issuerId
          issuerName
          receiverId
          receiverName
          taxRate
          remaining
          summary
          remaining
          period
          flexiblePeriod
          innerComments {
            user {
              id
              name
              email
            }
            date
            comment
          }
        }
      }
      meta {
      ... on ObligationMeta {
        family
        familyLabel
        type
        typeLabel
        description
        date
        number
        amount
        currency
        isPaid
        remaining
        originalAmount
        taxRate
        issuerId
        issuerName
        receiverId
        receiverName
        isReceived
        period
        flexiblePeriod
        detail {
          description 
          item
          quantity
          unit_price
          total_price
        }
        innerComments {
          user {
            id
            name
            email
          }
          date
          comment
        }
      }
    }
    meta {
      ... on CreditsMeta {
        installments
        interest
        stock
        totalPayments
        paymentNumber
        isPaid
        identificator
        period
        flexiblePeriod
        remaining
        innerComments {
          user {
            id
            name
            email
          }
          date
          comment
        }
      }
    }
  }
}`;

export const FindDocumentById = (variables) => {
  const [result, reexecuteQuery] = useQuery({
    query: FIND_DOCUMENT_BY_ID,
    variables,
  });

  const { data, fetching, error } = result;
  return { data, fetching, error, reexecuteQuery };
};

const UPDATE_XML_BY_MONTH = `mutation UPDATE_XML_BY_MONTH(
  $companyId: String!,
  $month: Float!,
  $year: Float!,
  ) {
    updateXMLByMonth(
      companyId: $companyId,
      month: $month,
      year: $year,
    )
}`;

export const UpdateXMLByMonth = () => {
  const [result, executeMutation] = useMutation(UPDATE_XML_BY_MONTH);
  return executeMutation;
};

const PAY_UNPAY_BY_ID = `mutation PAY_UNPAY_BY_ID(
  $companyId: String!,
  $data: typeMasive!,
){
  payUnpayById(
    companyId: $companyId,
    data: $data
  )
}`;

export const PayUnppayById = () => {
  const [result, executeMutation] = useMutation(PAY_UNPAY_BY_ID);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const GET_ISSUER_PENDING_DOCS = `query GET_ISSUER_PEDNING_DOCS($issuerId: String!, $companyId: String!) {
  getIssuerPendingDocs(issuerId: $issuerId, companyId: $companyId) {
      id
      currencyType
      companyId
      name
      description
      amount
      documentDate
      isHidden
      isManual
      documentType
      fieldKey
      meta {
        ... on TaxDocumentMeta {
          documentId
          documentCode
          originalAmount
          netAmount
          downloadURL
          taxAmount
          issuedAt
          dueDate
          isPaid
          isReceived
          paymentDate
          issuerId
          issuerName
          receiverId
          receiverName
          taxRate
          remaining
          summary
          remaining
          period
          flexiblePeriod
        }
      }
  }
}`;

export const GetIssuerPendingDocs = (variables) => {
  const [result, reexecuteQuery] = useQuery({
    query: GET_ISSUER_PENDING_DOCS,
    variables,
  });

  const { data, fetching, error } = result;
  return { data, fetching, error, reexecuteQuery };
};

const ACCEPT_SII_DOCUMENTS = `mutation ACCEPT_SII_DOCUMENTS($companyId: String!,
  $codeEvent: String!, 
  $documents: [DocAcceptanceParams!]!) {
    acceptDocumentsSii(
      companyId: $companyId
      codeEvent: $codeEvent
      documents: $documents
    ) {
      status
      message
      data
      warning
    }
}`

export const AcceptSiiDoc = () => {
  const [result, acceptanceSiiDoc] = useMutation(ACCEPT_SII_DOCUMENTS);
  return { acceptanceSiiDoc, result }
};

const MODIFY_DOCUMENT = `mutation MODIFY_DOCUMENT(
    $companyId: String!,
    $input: [DocumentToModify!]!,
  ){
    modifyDocument(
      companyId: $companyId,
      input: $input,
    ) {
      ok
      nModified
    }
  }`;

export const ModifyDocument = () => {
  const [result, executeMutation] = useMutation(MODIFY_DOCUMENT);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const SETTLE_DOCUMENT = `mutation SETTLE_DOCUMENT(
    $companyId: String!,
    $input: [DocumentToSettle!]!,
  ){
    settleDocument(
      companyId: $companyId,
      input: $input,
    ) {
      ok
      nModified
    }
  }`;

export const SettleDocument = () => {
  const [result, executeMutation] = useMutation(SETTLE_DOCUMENT);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const CREATE_CHECK_DOCUMENT = `mutation CREATE_CHECK_DOCUMENT(
    $companyId: String!,
    $documentId: String!,
    $input: [CheckDocumentInput!]!,
  ){
    createCheckDocument(
      companyId: $companyId,
      documentId: $documentId,
      input: $input,
    ) {
      id
    }
  }`;

export const CreateCheckDocument = () => {
  const [result, executeMutation] = useMutation(CREATE_CHECK_DOCUMENT);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const SETTLE_CONFLICT_DOC_NOMINA = `mutation SETTLE_CONFLICT_DOC_NOMINA(
    $documentId: String!,
    $isRelated: Boolean!,
    $selectionIds: [String!] ) {
      settleConflictDocumentNomina(
        documentId: $documentId
        isRelated: $isRelated
        selectionIds: $selectionIds
      ) {
        status
        message
        data
      }
  }`

export const SettleConflictDocNomina = () => {
  const [result, settleConflictSocNomina] = useMutation(SETTLE_CONFLICT_DOC_NOMINA)
  return settleConflictSocNomina;
}

const CREATE_DTE_INNER_COMMENT = `
mutation CREATE_DTE_INNER_COMMENT($input: InnerComment!) {
  createDteInnerComment(input: $input) {
    id
  }
}
`

export const CreateDteInnerComment = () => {
  const [result, executeMutation] = useMutation(CREATE_DTE_INNER_COMMENT);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const GET_BUK_IMPUESTO_UNICO = `mutation getBukImpuestoUnicoAmount(
  $companyUrl: String!, $token:String!, $date:DateTime! 
){
  getBukImpuestoUnicoAmount(
    companyUrl: $companyUrl,
    token:$token,
    date:$date
  ){
    status
    message
    amount
  }
}`;

export const GetBukImpuestoUnicoAmount = () => {
  const [result, executeMutation] = useMutation(GET_BUK_IMPUESTO_UNICO);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const UPDATE_DOCUMENT_CASH_FLOW_AREA_ID = `mutation updateDocumentCashFlowAreaId(
  $documentType: String!, $companyId:String!, $documentId:String!, $cashflowAreaId: String!
){
  updateDocumentCashFlowAreaId(
    documentType: $documentType,
    companyId:$companyId,
    documentId:$documentId,
    cashflowAreaId:$cashflowAreaId
  ){
    id
  }
}`;

export const UpdateDocumentCashFlowAreaId = () => {
  const [result, executeMutation] = useMutation(UPDATE_DOCUMENT_CASH_FLOW_AREA_ID);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const UPDATE_DOCUMENTs_CASH_FLOW_AREA_ID = `mutation updateDocumentsCashflowAreaId(
  $input: UpdateDocumentsCashflowAreaIdInput!
){
  updateDocumentsCashflowAreaId(
    input: $input
  ){
    ok
    nModified
  }
}`;

export const UpdateDocumentsCashflowAreaId = () => {
  const [result, executeMutation] = useMutation(UPDATE_DOCUMENTs_CASH_FLOW_AREA_ID);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};

const REMOVE_NOTE_FROM_DOCUMENT = `mutation removeNoteFromDocument(
  $companyId: String!,
  $documentId: String!,
  $noteIndexToRemove: Float!,
){
  removeNoteFromDocument(
    companyId: $companyId,
    documentId: $documentId,
    noteIndexToRemove: $noteIndexToRemove
  ){
    ok
    nModified
  }
}`;

export const RemoveNoteFromDocument = () => {
  const [result, executeMutation] = useMutation(REMOVE_NOTE_FROM_DOCUMENT);
  const { data, fetching, error } = result;
  return { data, fetching, error, executeMutation };
};